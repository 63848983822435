@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Mont';
    src: local('Mont'), url('../fonts/Mont/Mont-Regular.otf') format('opentype');
  }
  @font-face {
    font-family: 'Mont';
    font-weight: semibold;
    src: local('Mont'),
      url('../fonts/Mont/Mont-SemiBold.otf') format('opentype');
  }
  @font-face {
    font-family: 'Mont';
    font-weight: bold;
    src: local('Mont'), url('../fonts/Mont/Mont-Bold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Lato';
    font-weight: semibold;
    src: local('Lato'),
      url('../fonts/Lato/Lato-Semibold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Lato';
    font-weight: bold;
    src: local('Lato'), url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
  }
}

@layer base {
  h1 {
    @apply font-extrabold text-4xl align-middle text-black font-title;
  }
  h2 {
    @apply font-extrabold text-2xl align-middle text-black font-title;
  }
  h3 {
    @apply font-extrabold text-xl align-middle text-black font-title;
  }
  h4 {
    @apply font-extrabold text-lg align-middle text-black font-title;
  }
  h5 {
    @apply font-extrabold text-sm align-middle text-black font-title;
  }
  body {
    @apply font-body;
  }
  input {
    @apply font-body;
  }
}

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: #eaf8ff;
}

a {
  color: #2bb9ff;
}

button:active,
button:focus {
  outline: none;
}

.navbar {
  background-color: #3363ff;
}

.footer {
  color: #1e81b2;
}

.bg-default-blue {
  background-color: #eaf8ff;
}

.text-orange {
  color: #ff7e44;
}

.text-green {
  color: #7de481;
}

.btn-primary {
  background-color: #ff7e44;
  border: 1px solid #ff7e44;
  color: #fff;
  font-weight: 600;
  border-radius: 1rem;
  padding: 0.6rem 1rem;
}

.btn-primary:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-primary:hover {
  background-color: #fd7231;
}

.btn-secondary {
  background-color: #fff;
  border: 1px solid #ff7e44;
  color: #ff7e44;
  font-weight: 600;
  border-radius: 1rem;
  padding: 0.6rem 1rem;
}

.btn-link {
  background-color: transparent;
  color: #2bb9ff;
}

.input-borded {
  border: 0.6px solid #155c80;
}

.locate {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  z-index: 10;
}

.autocomplete-search {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 10;
}

.lista {
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 2em;
  border-radius: 1em;
  background: dodgerblue;
  margin: 0 1em;
  display: inline-block;
  color: white;
  position: relative;
  font-weight: bolder;
  font-size: 1.2em;
}

.lista::before {
  content: '';
  position: absolute;
  top: 0.9em;
  left: -4em;
  width: 4em;
  height: 0.1em;
  background: #fda460;
  z-index: -1;
}

.lista:first-child::before {
  display: none;
}

.activeLista {
  font-size: 1.3em;
}

.activeLista ~ .lista {
  background: skyblue;
}

.activeLista ~ .lista::before {
  background: skyblue;
}

.textoLista {
  position: absolute;
  font-size: 0.7em;
  bottom: -33px;
  left: -50%;
  color: black;
}

.btn-blue-default {
  background-color: #d6e0ff;
}

.btn-blue-active {
  background-color: #3363ff;
  color: #ffffff;
}

.border-default-blue {
  border-color: #3363ff;
  --tw-border-opacity: 1;
}
